import React, { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"

import { encode } from "../../utils"
import Arrow from "../../images/arrow.svg"

const FORM_INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  subject: "",
  message: "",
}

const FORM_NAME = "contact"

const FORM_PLACEHOLDERS = {
  firstName: "First",
  lastName: "Last",
  email: "Type your e-mail address @",
  subject: "Type the subject of your email",
  message: "Type your message here",
}

const Form = () => {
  const [formValues, setFormValues] = useState({ ...FORM_INITIAL_STATE })
  const [success, setSuccess] = useState(false)

  const inputChange = (e) => {
    const { name, value } = e.target
    setFormValues((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formValues,
      }),
    })
      .then(() => {
        setSuccess(true)
        setFormValues({ ...FORM_INITIAL_STATE })
        setTimeout(() => {
          setSuccess(false)
        }, 1250)
      })
      .catch((error) => console.error(error))
  }

  return (
    <div className="form-container">
      <form
        name={FORM_NAME}
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value={FORM_NAME} />
        <input type="hidden" name="bot-field" />

        <div className="name">
          <div className="first-name">
            <label htmlFor="firstName">First name</label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              placeholder={FORM_PLACEHOLDERS.firstName}
              value={formValues.firstName}
              onChange={(e) => inputChange(e)}
              required
            />
          </div>
          <div className="last-name">
            <label htmlFor="lastName">Last name</label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              placeholder={FORM_PLACEHOLDERS.lastName}
              value={formValues.lastName}
              onChange={(e) => inputChange(e)}
              required
            />
          </div>
        </div>
        <div className="email">
          <label htmlFor="email">E-mail</label>
          <input
            id="email"
            name="email"
            type="email"
            placeholder={FORM_PLACEHOLDERS.email}
            value={formValues.email}
            onChange={(e) => inputChange(e)}
            required
          />
        </div>
        <div className="subject">
          <label htmlFor="subject">Subject</label>
          <input
            id="subject"
            name="subject"
            type="text"
            placeholder={FORM_PLACEHOLDERS.subject}
            value={formValues.subject}
            onChange={(e) => inputChange(e)}
            required
          />
        </div>
        <div className="message">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            cols="50"
            rows="10"
            placeholder={FORM_PLACEHOLDERS.message}
            value={formValues.message}
            onChange={(e) => inputChange(e)}
            required
          />
        </div>
        <div className="buttons">
          <AnimatePresence>
            {success && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="success"
              >
                Form sent
              </motion.span>
            )}
          </AnimatePresence>
          <button type="submit" className="send">
            <span>Send</span>
            <img src={Arrow} alt="arrow" />
          </button>
        </div>
      </form>
    </div>
  )
}

export default Form
