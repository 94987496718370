import React from "react"
import SEO from "../components/seo"

import PageHeadline from "../components/pageHeadline/PageHeadline.component"
import Form from "../components/form/Form.component"

const Contact = () => {
  return (
    <>
      <SEO title="Contact Us" />
      <PageHeadline headline1="Contact" headline2="Us" />
      <Form />
    </>
  )
}

export default Contact
