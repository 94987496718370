import React from "react"
import { motion } from "framer-motion"

const PageHeadline = ({ headline1, headline2, color = "#F8F8F8" }) => {
  const transition = { duration: 0.7, ease: [0.165, 0.84, 0.44, 1] }

  const h1Variant = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  }

  return (
    <section className="page-headline" style={{ color }}>
      <motion.h1
        variants={h1Variant}
        initial="initial"
        animate="animate"
        transition={transition}
      >
        {headline1}
      </motion.h1>
      <motion.h1
        variants={h1Variant}
        initial="initial"
        animate="animate"
        transition={transition}
      >
        {headline2}
      </motion.h1>
    </section>
  )
}

export default PageHeadline
